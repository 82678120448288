export const commonProps = {
  // 组件的位置
  index: {
    type: Number,
    default: 0
  },
  context: {
    type: Object,
    default: () => ({})
  },
  content: {
    type: Array,
    default: () => ([])
  },
  propData: {
    type: Object,
    default: () => ({}),
  },
  propHooks: {
    type: Object,
    default: () => (null),
  },
  sceneData: {
    type: Object,
    default: () => ({}),
  },
  cateLinks: {
    type: Object,
    default: () => ({})
  },
  brand: {
    type: String,
    default: 'shein',
  },
  // isLoad: {
  //   type: Boolean,
  //   default: false,
  // },
  lazyForceShow: {
    type: Boolean,
    default: false,
  },
  containerWidth: {
    type: Number,
    default: 1275
  }
}
