<template>
  <div
    ref="superWideRef"
    class="super-wide-loop scroll-container"
    @mousedown="touchstartEvent"
    @mousemove="touchmoveEvent"
    @mouseup="touchendEvent"
    @mouseout="touchendEvent"
    @click="handleImgClick"
  >
    <div
      ref="scrollWrapRef"
      class="scroll-wrap"
      :style="[calcMaxWide, scollStyle]"
      @transitionend="transitionendEvent"
      @dragstart.prevent
    >
      <div
        v-for="list in loopNum"
        :key="list"
        class="scroll-item"
      >
        <div :style="[calcMaxWide]">
          <BaseImg
            v-if="item.image"
            v-tap="getAnalysisData('2-19-2', handleAnalysisData(item, index))"
            v-expose="getAnalysisData('2-19-1', handleAnalysisData(item, index))"
            :placeholder="{
              width: item.image.width,
              height: item.image.height
            }"
            fit="initial"
            :ratio="item.image.ratio"
            :img-src="item.image.src"
            :first-screen="propData?.isFirstPage"
            :ada="item.ada"
            :alt="item.hrefTitle"
            :is-support-webp="propData.isSupportWebp"
            :img-design-width="calcWideValue < imgDesignWidth ? imgDesignWidth : item.image.width"
            class="fsp-element"
            :comp-src="'static-image-superwideloop'"
          />
        </div>
        <HotZone
          v-if="showHotZone"
          :index="index"
          :context="context"
          :language="context.language"
          :prop-data="propData"
          :scene-data="sceneData"
          :cate-links="cateLinks"
          :get-analysis-data="getAnalysisData"
          @click.stop
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg.vue'
import HotZone from './HotZone.vue'

import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import mixins from 'public/src/pages/components/ccc/components/mixins/mixin.js'

export default {
  name: 'SwiperWideLoop',
  components: {
    BaseImg,
    HotZone
  },
  directives: { tap, expose },
  mixins: [mixins],
  props: {
    ...commonProps,
    item: {
      type: Object,
      default: () => ({})
    },
    showHotZone: {
      type: Boolean,
      default: false
    },
    imgDesignWidth: {
      type: Number,
      default: 1275
    },
  },
  emits: ['click'],
  data() {
    const { props = {}, autoSlide = {} } = this.propData || {}
    const { metaData = {} } = props
    const { hrefConfig = {} } = metaData
    return {
      autoSlide,
      hrefConfig, // 图片跳转配置
      isLeft: autoSlide?.autoSlideDirection === 'left', // 是否向左滑动
      loopNum: 2, // dom数量
      isCanLoop: true, // 是否可循环
      currentOffset: 0, // 当前容器偏移位置
      timerIns: null, // 定时器实例
      speed: 0, // 过度效果时间
      startX: 0, // 触摸开始位置
      isMouseDowning: false, // 正在拖动标志
      isContinue: false, // 拖动继续滚动标志
      translateTime: 0, // 拖动时间用来判断是拖动还是点击
      distance: 0, // 拖动距离配合时间要判断是否点击
      pageList: [] // 获取节点
    }
  },
  computed: {
    calcMaxWide() {
      return {
        width: this.calcWideValue + 'px',
        minWidth: '100%',
      }
    },
    calcWideValue() {
      const w = this.item?.image?.width
      const h = this.item?.image?.height
      const maxHeight = 400
      return  Number(((maxHeight / h) * w).toFixed(2))
    },
    scollStyle() {
      return {
        transform: `translate3d(${this.currentOffset}px, 0, 0)`,
        transition: `transform ${this.speed}s linear`,
      }
    },
    // 图片宽度
    picWidth() {
      const w = this.$refs.scrollWrapRef.getBoundingClientRect().width
      return w
    },
  },
  mounted() {
    this.$nextTick(() => {
      const cWidth = this.$refs.superWideRef?.getBoundingClientRect()?.width
      const sWidth = this.$refs.scrollWrapRef?.getBoundingClientRect()?.width
      this.isCanLoop = sWidth > cWidth
      // console.log(this.isCanLoop)
      if (this.isCanLoop) {
        this.loopNum = 2
        this.pageList = this.$refs?.scrollWrapRef.querySelectorAll('.scroll-item')
        this.startScroll()
      } else {
        this.loopNum = 1
      }
    })
  },
  beforeUnmount() {
    clearTimeout(this.timerIns)
  },
  methods: {
    startScroll() {
      // 用个宏任务先等dom归位再执行动画
      this.timerIns = setTimeout(() => {
        if (this.isLeft) {
          this.speed = this.autoSlide.autoSlideTime
          this.currentOffset = -this.picWidth
        } else {
          this.translate()
          this.speed = this.autoSlide.autoSlideTime
          this.currentOffset = this.picWidth
        }
        // 如果是拖动后继续滚动计算时间来维持匀速
        if (this.isContinue) {
          this.isContinue = false
          const remainTranslate = Math.abs(this.currentOffset) - Math.abs(this.getCurrentLeft())
          const remainTime = remainTranslate / Math.abs(this.currentOffset) * this.speed
          this.speed = Math.abs(remainTime)
        }
      }, 0)
    },
    // 滚动结束循环调用开始滚动事件
    transitionendEvent() {
      this.speed = 0
      this.currentOffset = 0
      this.startScroll()
    },
    touchstartEvent(e) {
      this.translateTime = Date.now()
      this.distance = 0
      if (!this.isCanLoop) {
        return
      }
      this.isMouseDowning = true
      // 触摸定位到获取当前left距离并且不再滚动
      this.speed = 0
      this.currentOffset = this.getCurrentLeft()

      this.startX = e.pageX
    },
    touchmoveEvent(e) {
      if (!this.isCanLoop) {
        return
      }
      if (!this.isMouseDowning) {
        return
      }
      this.distance = e.pageX - this.startX
      this.startX = e.pageX
      this.currentOffset = this.currentOffset + this.distance
      
      this.translate()
    },
    touchendEvent() {
      this.translateTime = Date.now() - this.translateTime
      if (!this.isCanLoop) {
        return
      }
      if (!this.isMouseDowning) {
        return
      }
      this.isMouseDowning = false
      this.isContinue = true
      this.correctionPosition()
      this.startScroll()
    },
    // 拖动判断
    translate() {
      const _superWidth = this.$refs?.superWideRef.getBoundingClientRect().width
      const minOffset = -(this.pageList.length * this.picWidth - _superWidth)
      // 向左移动超过最大移动界限把第一张图移到后面
      if (this.currentOffset <= minOffset) {
        this.pageList[0].style = `transform: translate3d(${this.pageList.length * this.picWidth}px, 0, 0)`
      } else {
        this.pageList[0].removeAttribute('style')
      }
      // 向右拖动如果左边没有图了则把第二张图片移过去
      if (this.currentOffset >= 0) {
        this.pageList[this.pageList.length - 1].style = `transform: translate3d(-${this.pageList.length * this.picWidth}px, 0, 0)`
        // 向右滚动然后再向右拖动超过挪过去的第二张图还需把第一张图移到左边
        if (this.currentOffset >= this.picWidth) {
          this.pageList[0].style = `transform: translate3d(-${this.pageList.length * this.picWidth}px, 0, 0)`
        }
      } else {
        this.pageList[this.pageList.length - 1].removeAttribute('style')
      }
    },
    // 手动拖动后修正偏移位置
    correctionPosition() {
      this.pageList[0].removeAttribute('style')
      this.pageList[this.pageList.length - 1].removeAttribute('style')
      if (this.currentOffset >= 0) {
        this.currentOffset = this.currentOffset % this.picWidth
        if (this.isLeft) {
          this.currentOffset = this.currentOffset - this.picWidth
        }
        if (!this.isLeft) {
          this.translate()
        }
      }
      // 如果是向左移动的且移动超过第一张图则定位到第一张的相对位置
      if (this.currentOffset < 0) {
        this.currentOffset = this.currentOffset % -this.picWidth
        if (!this.isLeft) {
          this.currentOffset = this.currentOffset + this.picWidth
          this.translate()
        }
      }
    },
    // 获取当前滚动容器偏移量
    getCurrentLeft() {
      const wrapperLeft = this.$refs?.scrollWrapRef?.getBoundingClientRect()?.x
      const extraSpace = this.$refs?.superWideRef?.getBoundingClientRect()?.x // 组件与页面侧间距
      return wrapperLeft - extraSpace
    },
    handleImgClick() {
      // 如果大于100ms或拖动距离不为0算拖动
      // console.log(this.translateTime, this.distance)
      if (this.translateTime >= 100 || this.distance !== 0) {
        return
      }
      this.$emit(
        'click',
        {
          ...this.item,
          ...this.hrefConfig
        },
        0
      )
    },
    /**
     * 埋点参数处理
     */
    handleAnalysisData(item, index) {
      return {
        item: {
          ...item,
          ...this.hrefConfig
        },
        index
      }
    }
  }
}
</script>

<style lang="less" scoped>
[mir=rtl] .super-wide-loop {
  direction: ltr;
}
.super-wide-loop {
  width: 100%;
  overflow: hidden;
  .scroll-wrap {
    display: flex;
  }
  .scroll-item {
    width: 100%;
    position: relative;
  }
}
</style>
