
import { ClientOnly } from '@sheinfe/vue-client-only'
import StaticImage from 'public/src/pages/components/ccc/home-page-components/static-image/index.vue'
import { hydrateWhenVisible } from '@shein-aidc/shein-lazy-hydration'

const componentsMap = {
  // 静态图片（单图、双图、三图）
  StaticImage,
  // 1/3组件
  OneThirdContainerComponent: hydrateWhenVisible(() => import(/* webpackChunkName: "home-one-third-component" */'./one-third-combine')),
  // 不贵组件
  SuperDeals: hydrateWhenVisible(() => import(/* webpackChunkName: "home-ccc-super-deals" */'./super-deals/SuperDeals.vue')),
  // 排行榜入口
  RankingEntrance: hydrateWhenVisible(() => import(/* webpackChunkName: "home-ccc-ranking-entrance" */'./rank/RankingEntrance')),
  // 横向商品列表
  HorizontalItems: hydrateWhenVisible(() => import(/* webpackChunkName: "home-ccc-horizontal-items" */'./HorizontalItems.vue')),
  // 纵向商品列表
  VerticalItems: hydrateWhenVisible(() => import(/* webpackChunkName: "home-ccc-vertical-items" */'./VerticalItems.vue')),
  // 滑动多图组件 （轮播图、横滑3、4、5、6）
  SideslipImage: hydrateWhenVisible(() => import(/* webpackChunkName: "home-ccc-sideslip-image" */'./SideslipImage.vue')),
}
componentsMap['ClientOnly'] = ClientOnly

export default componentsMap
