// import { defineAsyncComponent } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
// import lazyComponent from 'public/src/services/lazyComponent/lazy_hydrated.js' // 组件懒加载
import StaticImage from './static-image/index.vue'
import { hydrateWhenVisible } from '@shein-aidc/shein-lazy-hydration'

const componentsMap = {
  // 单图、双图、三图
  StaticImage,
  // 轮播图
  SideslipImage: hydrateWhenVisible(() => import(/* webpackChunkName: "store-sideslip-image" */'./sideslip-image/SideslipImage.vue')),
  // 滑动多图组件
  StoreSideslipImage: hydrateWhenVisible(
    () => import(/* webpackChunkName: "store-sideslip-image" */'./sideslip-image/SideslipImage.vue')
  ),
  // 一行五品牌
  BrandRecommend: hydrateWhenVisible(() => import(/* webpackChunkName: "store-brand-recommend" */'./brand-recommend/index')), 
  // 店铺品类推荐
  StoreCategoryDisplayRecommend: hydrateWhenVisible(() => import(/* webpackChunkName: "store-category-display-recommend" */'./store-category-display-recommend/store-category-display-recommend.vue')),
  // 单活动banner 多活动banner
  StorePageSingleActivityBanner: hydrateWhenVisible(() => import(/* webpackChunkName: "store-single-activity-banner" */'./activity-banner/single-activity-banner.vue')),
  // 品牌展示banner
  BrandDisplay: hydrateWhenVisible(() => import(/* webpackChunkName: "store-brand-display" */'./brand-display-comp/index.vue')),
  // 品类展示banner
  CategoryDisplay: hydrateWhenVisible(() => import(/* webpackChunkName: "store-category-display" */'./category-display-comp/index.vue')),
  // 活动小banner
  ActivityBanner: hydrateWhenVisible(() => import(/* webpackChunkName: "store-activity-banner" */'./activity-banner/index.vue')),
  // 推荐榜单入口
  RankRecommend: hydrateWhenVisible( () => import(/* webpackChunkName: "store-rank-recommend" */'./rank/RankRecommend.vue'),
  ), 
}

// const components = Object.entries(componentsMap).reduce((prev, cur) => {
//   prev[`${cur[0]}`] = lazyComponent({
//     componentFactory: cur[1],
//   })
//   if (['HorizontalAnchor', 'HorizontalAnchorImage'].includes(cur[0])) {
//     prev[`${cur[0]}`] = cur[1]
//   }
//   return prev
// }, {})
componentsMap['ClientOnly'] = ClientOnly
// componentsMap['LazyHydrationWrapper'] = LazyHydrationWrapper

export default componentsMap
