
import schttp from 'public/src/services/schttp'
import { CccLink } from 'public/src/pages/components/ccc/common/ccc-link.js'
// import { windowCacheDeleteCb } from '@shein/common-function'

let cccLink = null
// let asyncOperationPromise = null

// 确保performAsyncOperation()只被调用一次
// const ensureAsyncOperation = () => {
//   if (!asyncOperationPromise) {
//     // 若未初始化，则执行异步操作并存储promise
//     asyncOperationPromise = windowCacheDeleteCb({ key: 'campaignsDynamicData' })
//   }
//   // 返回这个promise
//   return asyncOperationPromise
// }

/*
** sceneData: 页面场景配置
** propData: 组件配置
** context: 全局配置
** cateLinks: 分类链接
** index: 组件索引
** options: 配置项 { scene: 'special', isReturnUrl: false }
** scene: sw代理、页面预取场景 1、cccx 2、special
**/

export const prefetchHtml = async ({ sceneData, propData, context, cateLinks, index, options = {} } = {}) => {
  try {
    const { scene = 'cccx' } = options || {}
    const { host, SW_FETCH_HTML_CONFIG = {} } = window.gbCommonInfo || {}
    const { enableSceneMap = [], preFetchSceneMap = [] } = SW_FETCH_HTML_CONFIG || {}
    // 是否开启sw页面代理
    if (!enableSceneMap.includes(scene)) return
    // 页面是否预取
    if(!preFetchSceneMap.includes(scene)) return
    
    cccLink = cccLink || new CccLink({
      sceneData,
      propData,
      context,
    })
    // await ensureAsyncOperation()
    let items = propData?.props?.items || []
    try {
      if (propData?.__comp_name === 'OneThirdContainerComponent') {
        items = propData?.content?.reduce((prev, cur) => {
          if (['ONE_THIRD_IMAGE', 'ONE_THIRD_IMAGE_CAROUSEL'].includes(cur.styleType)) {
            prev.push(...cur?.props?.items)
          }
          return prev
        }, [])
      }
    } catch (error) {
      console.error(error)
    }
    if (!items?.length) return
    items.forEach((item, i) => {
      if (item?.hrefType === 'activityNew') {
        const url = cccLink.getFullLink({
          item,
          cateLinks,
          compIndex: index,
          index: i,
        })
  
        if (window.SW_Loaded) {
          schttp({
            url: `${host}${url}`,
            schttp: { apiLogSkip: true }
          })
          return
        }
        window.addEventListener('SW_Loaded', () => {
          schttp({
            url: `${host}${url}`,
            schttp: { apiLogSkip: true }
          })
        })
      }
    })
  } catch (error) {
    console.error('预取页面失败', error)
  }
}
