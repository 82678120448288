<template>
  <div>
    <div
      class="ccc-text"
      :style="{ backgroundColor }"
      @click="clickText($event)"
      v-html="html"
    ></div>
  </div>
</template>

<script>
import mixins from 'public/src/pages/components/ccc/components/mixins/mixin.js'

// 后台拼在标签上的属性，需要和后台保持一致
const ENTITY = 'data-entity'

// 阻止默认事件
const preventDefault = (e) => {
  if (e.preventDefault) {
    e.preventDefault()
  } else {
    e.returnValue = false
  }
}

// 对后台拼接数据进行解析，对应后台的加密方法 parseEntityUrl
const decodeEntityUrl = (attribute = '') => {
  const keys = attribute.split(',')
  if (keys.length) {
    return keys.reduce((obj, key = '') => {
      const [prev, next] = key.split('=')
      obj[prev] = next
      return obj
    }, {})
  }
  return {}
}

export default {
  name: 'CCCText',
  mixins: [mixins],
  props: {
    propData: {
      type: Object,
      default: () => ({}),
    },
    cateLinks: {
      type: Object,
      default: () => ({}),
    },
    sceneData: {
      type: Object,
      default: () => ({}),
    },
    backgroundColor: {
      type: String,
      default: 'inherit',
    },
    html: {
      // 后台直接将富文本生成 html 片段
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
    exposeInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      gaListName: '',
    }
  },
  methods: {
    handleGetFullLink(item) {
      return this.cccLink.getFullLink({
        item,
        cateLinks: this.cateLinks,
        compIndex: this.index,
      })
    },
    clickText(ev) {
      const event = ev || window.event
      preventDefault(event)
      const targetElement = (event.target || event.srcElement).closest('a')
      if (targetElement.length) {
        const entityUrl = decodeEntityUrl(targetElement.attr(ENTITY))
        const url = this.handleGetFullLink(entityUrl)
        if (!url || url.indexOf('javascript:;') > -1) return
        location.href = url
      }
    },
  },
}
</script>

<style lang="less">
.ccc-text{
  word-break: break-word;
  overflow-y: auto;
  overflow-x: hidden;
  color: #222;
  // max-height: 14rem;
  font-size: 12px;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  white-space: pre-wrap;
  padding: 10px 0;
  &::-webkit-scrollbar {
    display: none;
  }
  &.no-padding {
    padding: 0;
  }
  a {
    color: #2d68a8;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  ul,
  ol {
    margin: 16px 0;
    padding: 0;
  }
  li {
    margin-left: 1.5em;
    list-style: inherit;
  }
  em {
    font-style: italic;
  }
}
</style>
