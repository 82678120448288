<template>
  <div
    ref="staticImageRef"
    v-bottom-sticky="{
      isAllowFloat: styleType === 'ONE_IMAGE_COMPONENT' && enableFloatDisplayAfterReceive,
      styleType,
      compId: id
    }"
    :class="[
      isBottomFloat ? `bottom-fixed ${floatMaxWidthClass}` : 'bottom-static',
    ]"
  >
    <div
      :class="[
        'static-image',
        !disableBottomSpacing ? 'hasBottomSpace' : '',
        showHotZone ? 'full-width' : '',
      ]"
    >
      <!-- 超宽图样式 -->
      <template v-if="styleCase === 'superWide'">
        <template v-if="showSuperWideLoop">
          <SuperWideLoop
            v-for="(item, i) in items"
            :key="i"
            :item="item"
            :show-hot-zone="showHotZone"
            :img-design-width="imgDesignWidth"
            v-bind="$props"
            @click="clickItem"
          />
        </template>
        <template v-else>
          <SuperWide
            v-for="(item, i) in items"
            :key="i"
            :item="item"
            :show-hot-zone="showHotZone"
            :img-design-width="imgDesignWidth"
            v-bind="$props"
            @click="clickItem"
          />
        </template>
      </template>

      <!-- 悬浮图样式 -->
      <FloatStyle
        v-else-if="styleCase === 'floatStyle'"
        v-bind="$props"
        @click="clickItem"
      />
      <template v-else>
        <BaseImg
          v-for="(item, i) in items"
          :key="i"
          v-tap="getAnalysisData('2-19-2', { item, index: i })"
          v-expose="getAnalysisData('2-19-1', { item, index: i })"
          :class="{
            intervalSpacing,
          }"
          :style="calcStyle(i, items)"
          :placeholder="{
            width: item.image && item.image.width,
            height: item.image && item.image.height,
          }"
          :ratio="item.image && item.image.ratio"
          :brand="brand"
          :img-src="item.image && item.image.src"
          :img-design-width="imgDesignWidth"
          :img-data-exp="imgDataExp"
          :ada="item.ada"
          :alt="item.hrefTitle"
          :url="handleGetFullLink(item, i)"
          :first-screen="propData.firstScreen"
          :lcp-image="!!item.isLCPImage"
          @click="clickItem(item, i)"
        />
        <!-- 热区组件 -->
        <ClientOnly>
          <HotZone
            v-if="showHotZone"
            :index="index"
            :context="context"
            :language="context.language"
          />
        </ClientOnly>
      </template>
    </div>
  </div>
</template>

<script>
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg.vue'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import mixins from 'public/src/pages/components/ccc/components/mixins/mixin.js'
import HotZone from './HotZone.vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import FloatStyle from './FloatStyle.vue'
import SuperWide from './SuperWide.vue'
import SuperWideLoop from './SuperWideLoop.vue'
import { bottomSticky } from 'public/src/pages/components/ccc/base/sticky/bottom.js'
import { cccxEventBus } from 'public/src/pages/components/ccc/common/utils.js'

export default {
  name: 'StaticImage',
  components: {
    BaseImg,
    HotZone,
    ClientOnly,
    FloatStyle,
    SuperWide,
    SuperWideLoop
  },
  directives: {
    tap,
    expose,
    bottomSticky,
  },
  mixins: [mixins],
  props: commonProps,
  provide() {
    return {
      propData: this.propData,
      sceneData: this.sceneData,
      cateLinks: this.cateLinks,
      getAnalysisData: this.getAnalysisData,
      GB_cssRight: this.context?.GB_cssRight || false
    }
  },
  data() {
    const { props = {}, hotZones = { standard: [] }, autoSlide = {}, styleType, id } = this.propData || {}
    const { items = [], metaData = {} } = props
    const { disableBottomSpacing = true, intervalSpacing = false, enableFloatDisplayAfterReceive = false } = metaData

    // 是否超宽图 - 动作设置（目前仅支持静态单图）
    const showSuperWide = styleType === 'ONE_IMAGE_COMPONENT' && (autoSlide?.isAutoSlide)

    // 超宽且循环
    const showSuperWideLoop = showSuperWide && (autoSlide?.loopSlide)

    // 展示图类型（默认常规静态单图）
    const styleCase = showSuperWide ? 'superWide' : (styleType === 'FLOW_IMAGE_COMPONENT' ? 'floatStyle' : '')

    return {
      items,
      hotZones,
      disableBottomSpacing,
      intervalSpacing,
      styleCase,
      showSuperWideLoop,
      styleType,
      id,
      enableFloatDisplayAfterReceive,
      isBottomFloat: false,
    }
  },
  computed: {
    // 目前只有静态单图支持配置热区
    showHotZone() {
      return this.propData?.styleType === 'ONE_IMAGE_COMPONENT' && (this.hotZones?.standard?.length || this.hotZones?.timeTransform?.length || this.hotZones?.label?.length)
    },
    // 是否悬浮图样式
    isFloatStyle() {
      return this.propData?.styleType === 'FLOW_IMAGE_COMPONENT'
    },
    // 图片设计宽度
    imgDesignWidth() {
      if (this.items.length) {
        return Math.floor(this.containerWidth / this.items.length)
      }
      return this.containerWidth
    },
    imgDataExp() {
      return this.items.length === 1 ? { min: 2000, max: 2000 } : {}
    },
    floatMaxWidthClass() {
      const { pageWidth = '' } = JSON.parse(this.context?.content?.extend)
      const widthMap = {
        '1': '', // 自适应
        '2': 'float-max-width-1200', // 1200px
        '3': 'float-max-width-1800', // 1800px
      }
      return widthMap[pageWidth] || ''
    },
  },
  mounted() {
    // 监听悬浮图组件的悬浮事件
    cccxEventBus?.on?.(`float-${this.styleType}-${this.id}`, result => {
      if (result?.type === 'static') {
        this.isBottomFloat = false
      } else {
        this.isBottomFloat = true
      }
    })
  },
  unMounted() {
    cccxEventBus?.off?.(`float-${this.styleType}-${this.id}`)
  },
  methods: {
    handleGetFullLink(item, i) {
      return this.cccLink.getFullLink({
        item,
        cateLinks: this.cateLinks,
        compIndex: this.index,
        index: i,
      })
    },
    clickItem(item, i) {
      const url = this.handleGetFullLink(item, i)
      if (!url || url.indexOf('javascript:;') > -1) return
      if (item.hrefType === 'sku' && this.mixinLinkTo) {
        this.mixinLinkTo(url)
        return
      }
      this.clickCompMonitor()
      location.href = url
    },

    // 清除首个和最后一个图片的边距
    calcStyle(index, array) {
      let len = array?.length
      let result = {}
      // 单个图片 时 左右无边距
      if (len === 1) {
        result.marginLeft = 0
        result.marginRight = 0
      } else if (len > 1) {
        const { GB_cssRight = false } =
          typeof window === 'undefined' ? {} : gbCommonInfo
        const rtl = GB_cssRight
        let attr = 'marginLeft'
        if (index === 0) {
          attr = rtl ? 'marginRight' : 'marginLeft'
          result[attr] = 0
        } else if (index === len - 1) {
          attr = rtl ? 'marginLeft' : 'marginRight'
          result[attr] = 0
        }
      }
      return result
    },
  },
}
</script>

<style lang="less" scoped>
/* stylelint-disable selector-class-pattern */
.static-image {
  // margin: 0 50px;
  display: flex;
  flex-direction: row;
  // 单图有热区 设置宽高百分百
  &.full-width {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &.hasBottomSpace {
    padding-bottom: 16px;
    background-color: transparent;
  }

  .intervalSpacing {
    margin: 0 8px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
// &.bottom-static {}
.bottom-fixed {
  max-width: 100%;
  height: auto;
  margin: 0 -48px;
  padding: 0 48px;
  width: 100%;
}
.float-max-width-1200 {
  max-width: 1296px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
}
.float-max-width-1800 {
  max-width: 1896px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
}
</style>

<style lang="less">
.store-page-home--margin {
  .static-image {
    margin-bottom: 20px;
    &.hasBottomSpace {
      padding-bottom: 0px;
    }
  }
}
</style>

