<template>
  <div
    ref="superWideRef"
    :dir="isLeft ? 'ltr' : 'rtl'"
    class="super-wide"
    :style="isMoving || complateMove ? {} : {
      overflow: 'hidden',
      width: '100%',
    }"
    @mousedown="handleImgMouseDown"
    @mouseup="handleImgMouseStop"
    @mouseleave="handleImgMouseStop"
    @mousemove="handleImgMouseMove"
    @scroll.once="handleImgStop"
    @click="handleImgClick"
  >
    <div 
      ref="superWideWrapperRef"
      class="super-wide__wrapper"
      :style="[calcMaxWide, movingStyle]"
      @transitionend="handleImgStop"
      @dragstart.prevent
    >
      <BaseImg
        v-if="item.image"
        v-tap="getAnalysisData('2-19-2', handleAnalysisData(item, index))"
        v-expose="getAnalysisData('2-19-1', handleAnalysisData(item, index))"
        :placeholder="{
          width: item.image.width,
          height: item.image.height
        }"
        fit="initial"
        :ratio="item.image.ratio"
        :img-src="item.image.src"
        :first-screen="propData.firstScreen"
        :ada="item.ada"
        :alt="item.hrefTitle"
        :is-support-webp="propData.isSupportWebp"
        :img-design-width="calcWideValue < imgDesignWidth ? imgDesignWidth : item.image.width"
        class="fsp-element"
      />
      <HotZone
        v-if="showHotZone"
        :index="index"
        :context="context"
        :language="context.language"
        :prop-data="propData"
        :scene-data="sceneData"
        :cate-links="cateLinks"
        :get-analysis-data="getAnalysisData"
        @click.stop
      />
    </div>
  </div>
</template>

<script>
import BaseImg from '../../base/BaseImg.vue'
import HotZone from './HotZone.vue'

import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import mixins from 'public/src/pages/components/ccc/components/mixins/mixin.js'
import ExposeScroll from 'public/src/services/expose/index.js'

export default {
  name: 'SwiperWide',
  components: {
    BaseImg,
    HotZone
  },
  directives: { tap, expose },
  mixins: [mixins],
  props: {
    ...commonProps,
    item: {
      type: Object,
      default: () => ({})
    },
    showHotZone: {
      type: Boolean,
      default: false
    },
    imgDesignWidth: {
      type: Number,
      default: 1275
    },
  },
  emits: ['click'],
  data() {
    const { props = {}, autoSlide = {} } = this.propData || {}
    const { metaData = {} } = props
    const { hrefConfig = {} } = metaData
    return {
      autoSlide,
      hrefConfig, // 图片跳转配置
      isMoving: false,
      isPause: false,
      isLeft: autoSlide?.autoSlideDirection === 'left', // 是否向左滑动
      complateMove: false, // 自动滑动是否结束
      curTranslate: 0, // 当前translateX值
      finalTranslate: 0, // 最终translateX值
      isFirstAutoPlay: true, // 是否第一次自动播放
    }
  },
  computed: {
    isSuperWide() {
      return true
    },
    calcMaxWide() {
      return {
        width: this.calcWideValue + 'px',
        minWidth: '100%',
      }
    },
    calcWideValue() {
      const w = this.item?.image?.width
      const h = this.item?.image?.height
      const maxHeight = 400
      return  Number(((maxHeight / h) * w).toFixed(2))
    },
    movingStyle({ isMoving, isPause, isLeft, autoSlide, curTranslate, finalTranslate }) {
      const { autoSlideTime = 3 } = autoSlide || {}
      if (!isMoving || !finalTranslate) return {}
      const remainTranslate = Math.abs(finalTranslate) - Math.abs(curTranslate)
      const remainTime = remainTranslate / Math.abs(finalTranslate) * (autoSlideTime * 1000)

      return {
        willChange: 'transform',
        transform: `translate3d(${isLeft ? '-' : ''}${isPause ? curTranslate : finalTranslate}px, 0, 0)`,
        transition: `transform ${isPause ? 0 : remainTime}ms linear`,
      }
    },
  },
  mounted() {
    this.autoplay()
  },
  methods: {
    autoplay() {
      this.obInstance = new ExposeScroll({ observeHide: true, exposeRatio: 0.99 })
      this.obInstance.observe(
        {
          elements: this.$refs?.['superWideRef'],
          once: false,
        },
        ({ exposeDoms }) => {
          if (exposeDoms.length) {
            if (this.isFirstAutoPlay) {
              this.startMoving()
              this.isFirstAutoPlay = false
            }
            this.isPause = false
          } else {
            // 首次非视口时不触发暂停
            if (this.isFirstAutoPlay) return
            this.pauseMoving()
          }
        },
      )
    },
    startMoving() {
      const superWideRef = this.$refs?.['superWideRef']
      const superWideWrapperRef = this.$refs?.['superWideWrapperRef']
      const superWideRefWidth = superWideRef.offsetWidth
      const superWideWrapperRefWidth = superWideWrapperRef.offsetWidth
      const distance = superWideWrapperRefWidth - superWideRefWidth // 滚动总路程

      superWideRef.scrollLeft = 0 // 初始化起始滚动位置
      this.finalTranslate = distance
      this.isMoving = true // 开始自动滑动，移除样式未加载完时的默认style样式
    },
    pauseMoving() {
      this.curTranslate = this.isLeft ? Math.abs(this.getCurrentLeft()) : (Math.abs(this.finalTranslate) - Math.abs(this.getCurrentLeft()))
      this.$nextTick(() => {
        this.isPause = true
      })
    },
    // 触碰则停止自动滑动
    handleImgStop() {
      // 自动滑动结束、暂停状态、未开始滑动时，不触发终止滑动
      if (this.complateMove || this.isPause || !this.isMoving) return
      this.complateMove = true // 自动滑动结束
      const superWideRef = this.$refs?.['superWideRef']
      // 向右滑动时将组件设为rtl以初始化起始滚动位置，scrollLeft则要取负值
      superWideRef.scrollLeft = this.isLeft ? Math.abs(this.getCurrentLeft()) : (Math.abs(this.getCurrentLeft()) - Math.abs(this.finalTranslate))
      this.isMoving = false

      // 清除视口监听器
      this.obInstance?.destroy?.()
      this.obInstance = null
    },
    // 计算wrapper当前偏移量
    getCurrentLeft() {
      const superWideRef = this.$refs?.['superWideRef']
      const superWideWrapperRef = this.$refs?.['superWideWrapperRef']
      const extraSpace = superWideRef?.getBoundingClientRect()?.x // 组件与页面侧间距
      const wrapperLeft = superWideWrapperRef?.getBoundingClientRect()?.x
      return extraSpace - wrapperLeft
    },
    handleImgMouseDown(e) {
      this.handleImgStop()

      this.startMouseMoving = true
      const superWideRef = this.$refs?.['superWideRef']
      this.scrollLeft = superWideRef.scrollLeft // 当前滚动位置
      this.currentX = e.clientX // 当前鼠标按下位置
    },
    // 鼠标移动时，图片滚动移动
    handleImgMouseMove(e) {
      if (!this.startMouseMoving || this.isMoving) return
      this.superWideRef = this.superWideRef || this.$refs?.['superWideRef']
      this.superWideRef.scrollLeft = this.scrollLeft - (e.clientX - this.currentX)
    },
    // 处理鼠标移动停止，防止点击事件被触发
    handleImgMouseStop() {
      this.startMouseMoving = false
    },
    handleImgClick(e) {
      if (this.currentX !== e.clientX) return
      this.$emit(
        'click',
        {
          ...this.item,
          ...this.hrefConfig
        },
        0
      )
    },
    /**
     * 埋点参数处理
     */
    handleAnalysisData(item, index) {
      return {
        item: {
          ...item,
          ...this.hrefConfig
        },
        index
      }
    }
  }
}
</script>

<style lang="less" scoped>
@rem: 37.5rem;

.hidden-scroll() {
  /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
  -ms-overflow-style: none;
  /*火狐下隐藏滚动条*/
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;

  /*Chrome下隐藏滚动条，溢出可以透明滚动*/
  &::-webkit-scrollbar {
    display: none;
  }
}

.super-wide {
  position: relative;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  .hidden-scroll();

  &__wrapper {
    position: relative;
  }
}
</style>
