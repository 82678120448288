import { prefetchResource } from 'public/src/services/prefetchResource'

let prefetched = null

export const prefetchResources = ({ context, el }) => {
  const props = context?.props || {}
  const items = props?.items
  const hasCampaigns = items?.some(item => item?.hrefType === 'activityNew')
  if (!hasCampaigns || prefetched) return
  prefetched = true
  // el 必须是渲染完的
  prefetchResource.listen({
    el,
    prefetchList: [
      {
        chunkName: 'ccc-campaigns',
        relType: 'prefetch'
      }
    ],
    prefetchCallback: ({ status, info }) => {
      console.log('prefetchCallback', status, info)
    },
    delay: 2000, // 默认两秒
    ioCallback: () => {
      console.log('ioCallback')
    }
  })
}
